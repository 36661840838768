.react-datepicker__input-container {
    border-radius: 4px;
    border: 1px solid #c4c4c4;
}

.react-datepicker__input-container input {
    font: inherit;
    border: 0;
    height: 1.1876em;
    margin: 0;
    display: block;
    min-width: 0;
    background: none;
    box-sizing: content-box;
    animation-name: mui-auto-fill-cancel;
    letter-spacing: inherit;
    animation-duration: 10ms;
    -webkit-tap-highlight-color: transparent;
    padding: 18.5px 14px;
}

.react-datepicker {
    font-family: Nunito, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}


.react-datepicker-popper {
    z-index: 999999 !important;
}
