.landing-wrapper {
	margin: 0;
	padding: 0;
	font-family: Nunito, serif;
	font-size: 18px;
	color: #242E3F;
	line-height: 1.4 !important;
	background: #fff;

	.clear {
		clear: both;
		height: 0;
	}

	h1 {
		font-style: normal;
		font-weight: bold;
		font-size: 54px;
		line-height: 125%;
		color: #242E3F;
	}

	h2 {
		font-style: normal;
		font-weight: bold;
		font-size: 34px;
		line-height: 46px;
		color: #242E3F;
	}

	h3 {
		font-style: normal;
		font-weight: normal;
		font-size: 34px;
		line-height: 46px;
		color: #242E3F;
	}

	.text_button {
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		line-height: 25px;
		color: #242E3F;
	}

	.text_big_size {
		font-style: normal;
		font-weight: normal;
		font-size: 24px;
	}

	.text_small_size {
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
		line-height: 25px;
		color: #242E3F;
	}

	.number_big {
		font-style: normal;
		font-weight: 900;
		font-size: 150px;
		line-height: 205px;
		color: #1976D2;
	}

	.number_big_percent {
		font-style: normal;
		font-weight: 900;
		font-size: 80px;
		line-height: 109px;
		color: #1976D2;
		border: 10px solid #FFFFFF;
	}

	.number_small {
		font-style: normal;
		font-weight: 900;
		font-size: 68px;
		line-height: 98px;
		color: #1976D2;
	}

	header {
		background: #242E3F;
	}

	.header {
		display: flex;
		align-items: center;
		justify-content: space-between;

	}

	.header_left {
		float: left;
		margin: 30px 0 30px 140px;
	}

	.header_right {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 30px 185px 30px 0;
	}

	.login {
		margin-right: 60px;
	}

	.login a {
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		line-height: 25px;
		color: #FFFFFF;
		text-decoration: none;
	}

	.signup {
	}

	.signup a {
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		line-height: 25px;
		color: #FFFFFF;
		text-decoration: none;
		padding: 27px 90px 28px;
		background: #4CAF50;
		border-radius: 5px;
	}

	.signup a:hover {
		background: #54D059;
		transition: 0.3s linear;
	}

	.top_header {
		height: 980px;
	}

	.intro_title {
		margin-top: 84px;
		margin-bottom: 40px;
	}

	.intro_button a {
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		line-height: 25px;
		color: #FFFFFF;
		text-decoration: none;
		padding: 27px 130px 28px;
		background: #4CAF50;
		border-radius: 5px;
	}

	.intro_button a:hover {
		background: #54D059;
		transition: 0.3s linear;
	}

	.intro_text {
		margin-bottom: 60px;
	}

	.top_header {
		background: url("https://fswho.fra1.cdn.digitaloceanspaces.com/workhard_chat/header_tel.png") right 25% top 106px no-repeat, url("https://fswho.fra1.cdn.digitaloceanspaces.com/workhard_chat/header_chat.png") left 20% top no-repeat;
	}

	.bottom_header_title {
		width: 60%;
	}

	.excellence_num {
		position: relative;
	}

	.excellence_num_percent {
		position: absolute;
		background: url("https://fswho.fra1.cdn.digitaloceanspaces.com/workhard_chat/percent.png") 0 0 no-repeat;
		width: 93px;
		height: 80px;
	}

	.excellence_num_percent_90 {
		left: 145px;
		top: 20px;
	}

	.excellence_num_percent_100 {
		top: 25px;
		left: 235px;
	}

	.excellence_title {
		margin-bottom: 25px;
	}

	.answers {
		background: #e8f4fd;
	}

	.marketing {
		background: #E8F4FD;
		margin: 150px 0 100px;
		padding: 75px 0 100px 0;
		position: relative;
	}

	.marketing .container {
		background: url(https://fswho.fra1.cdn.digitaloceanspaces.com/workhard_chat/ico_telegrams.png) right top 30px no-repeat;
		background-size: 480px;
	}

	.marketing_title {
		margin-bottom: 60px;
	}

	.marketing_text {
		font-size: 30px;
	}

	.marketing_button {
		position: absolute;
		right: 40px;
		top: 50%;
	}

	.marketing_button a {
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		line-height: 25px;
		color: #FFFFFF;
		text-decoration: none;
		padding: 27px 65px 28px;
		background: #4CAF50;
		border-radius: 5px;
	}

	.marketing_button a:hover {
		background: #54D059;
		transition: 0.3s linear;
	}

	.tasks_text {
		position: relative;
		padding: 30px 0 0 45px;
		z-index: 0;
	}

	.tasks_text_main {
		z-index: 1;
	}

	.tasks_text_number {
		font-style: normal;
		font-weight: 900;
		font-size: 150px;
		line-height: 130px;
		color: #E8F4FD;
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
	}

	.tasks_text_2 {
		margin-left: 40px;
	}

	.tasks_text_img_1 {
		background: url("https://fswho.fra1.cdn.digitaloceanspaces.com/workhard_chat/ico_task_text.png") left bottom no-repeat;
	}

	.tasks_text_img_2 {
		background: url("https://fswho.fra1.cdn.digitaloceanspaces.com/workhard_chat/ico_task_text.png") right bottom no-repeat;
	}

	.tasks_text_img_3 {
		background: url("https://fswho.fra1.cdn.digitaloceanspaces.com/workhard_chat/ico_task_text.png") left bottom no-repeat;
		transform: scale(1, -1);
	}

	.tasks_text_img_3 img {
		transform: scale(1, -1);
	}

	.how_to_work {
		background: #E8F4FD;
		padding: 50px 0;
	}

	.how_to_work_list {
		position: relative;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		padding: 0 15px;
	}

	.how_to_work_list_item {
		width: 43%;
	}

	.how_to_work_border_1 {
		position: absolute;
		background: url("https://fswho.fra1.cdn.digitaloceanspaces.com/workhard_chat/border_1.png");
		width: 202px;
		height: 45px;
		top: -50px;
		left: 38%;
	}

	.how_to_work_border_2 {
		position: absolute;
		background: url("https://fswho.fra1.cdn.digitaloceanspaces.com/workhard_chat/border_2.png");
		width: 122px;
		height: 169px;
		top: 99px;
		left: 45%;
	}

	.how_to_work_border_3 {
		position: absolute;
		background: url("https://fswho.fra1.cdn.digitaloceanspaces.com/workhard_chat/border_3.png");
		width: 202px;
		height: 45px;
		top: 550px;
		left: 39%;
	}

	.how_to_work_border_4 {
		position: absolute;
		background: url("https://fswho.fra1.cdn.digitaloceanspaces.com/workhard_chat/border_4.png");
		width: 122px;
		height: 169px;
		top: 715px;
		left: 45%;
	}

	.how_to_work_text {
		margin-bottom: 100px;
	}

	.how_to_work_list_item {
		margin-bottom: 80px;
	}

	.how_to_work_list_item span {
		background: rgba(25, 118, 210, 0.3);
	}

	.how_to_work_list_item.how_to_work_4 {
		margin-top: -40px;
	}

	.how_to_work_list_item.how_to_work_6 {
		margin-top: 15px;
	}

	.statistic {
		text-align: center;
	}

	.statistic_data_text {
		color: #1976D2;
		font-weight: 900;
		font-size: 34px;
	}

	.statistic_button {
		margin: 50px 0;
	}

	.statistic_button a {
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		line-height: 25px;
		color: #FFFFFF;
		text-decoration: none;
		padding: 27px 107px 28px;
		background: #4CAF50;
		border-radius: 5px;
	}

	.statistic_button a:hover {
		background: #54D059;
		transition: 0.3s linear;
	}

	.what_do_have {
		background: url("https://fswho.fra1.cdn.digitaloceanspaces.com/workhard_chat/what_left.png") left 20% center no-repeat, url("https://fswho.fra1.cdn.digitaloceanspaces.com/workhard_chat/what_right.png") right 20% center no-repeat;
	}

	.what_do_haves_tab {
		overflow: hidden;
		display: none;
		padding: 50px 145px 50px 100px;
	}

	.what_do_haves_tab.active {
		display: block;
	}

	.what_do_haves {
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
		margin-bottom: 125px;
		background: #fff
	}

	.what_do_haves_links {
		display: flex;
		flex-direction: row;
		justify-content: space-around;
	}

	.what_do_haves_link {
		flex-grow: 1;
		text-align: center;
		padding: 30px 0;
		box-shadow: 5px 0 5px rgba(0, 0, 0, 0.1);
	}

	.what_do_haves_link.active {
		box-shadow: 5px 0 10px rgba(0, 0, 0, 0.3);
	}

	.what_do_haves_link a {
		color: #0D3C61;
		text-decoration: none;
		padding: 30px 48px;
	}

	.what_do_haves_tab {
		font-style: normal;
		font-weight: normal;
		font-size: 24px;
	}

	.what_do_haves_bottom {
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		padding: 0 0 70px 0;
	}

	.what_do_haves_bottom_right a {
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		line-height: 25px;
		color: #FFFFFF;
		text-decoration: none;
		padding: 27px 133px 28px;
		background: #4CAF50;
		border-radius: 5px;
	}

	.what_do_haves_bottom_right a:hover {
		background: #54D059;
		transition: 0.3s linear;
	}

	.answer_link {
		color: #1976D2;
		font-weight: bold;
		font-size: 24px;
		padding: 30px;
		background: #FFFFFF url("https://fswho.fra1.cdn.digitaloceanspaces.com/workhard_chat/arrow.png") right 50px top 40px no-repeat;
		box-shadow: 0px 14px 40px rgba(13, 60, 97, 0.24);
		margin: 0 0 20px;
		cursor: pointer;
	}

	.answer_text {
		display: none;
		font-weight: 400;
		font-size: 18px;
		line-height: 25px;
		color: #242E3F;
		margin-top: 20px;
	}

	.answer_link.active {
		background: #FFFFFF url("https://fswho.fra1.cdn.digitaloceanspaces.com/workhard_chat/arrow_active.png") right 50px top 40px no-repeat;
	}

	.answer_link.active .answer_text {
		display: block;
		animation: showBlock 1s linear forwards;
	}

	@keyframes showBlock {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}

	footer {
		background: #242E3F;
		padding: 0 0 35px;
		.payment-visa{
			height: 50px;
		}
		.payment-master{
			height: 50px;
		}
		.payment-webmoney{
			height: 50px;
			margin: 0 10px;
		}
		.payment-io{
			height: 50px;

		}
	}

	.pre_footer {
		margin-top: 100px;
		background: #242E3F;
	}

	.pre_footer_menu {
		display: flex;
		flex-direction: row;
		justify-content: center;
		padding: 30px 0;
	}

	.pre_footer_menu a {
		color: #fff;
		text-decoration: none;
		font-size: 18px;
		font-weight: 700;
		margin: 0 30px;
	}

	.footer_telegram_img img {
		float: left;
		margin-right: 15px;
	}

	.footer_telegram_text, .footer_copy {
		color: #fff;
		font-weight: 400;
		font-size: 18px;
		line-height: 25px;
	}

	.footer_telegram_text {
		margin-top: 15px;
		float: left;
	}

	.footer_telegram_text a {
		color: #fff;
		text-decoration: none;
	}

	.footer_copy {
		margin-top: 15px;
		margin-right: 15px;
	}

	.footer_logo {
		text-align: center;
		img {
			width: 100%;
		}
	}

	.inner_mobile {
		display: none;
	}

	ul {
		padding: 0 0 0 35px;
		list-style: disc;
	}

	ul li {
		color: #1976D2;
		margin-bottom: 15px;
	}

	ul li span {
		color: #242E3F;
	}

	h2.statistic_bottom {
		margin-top: 50px;
	}

	h2.what_do_have_title {
		margin-bottom: 40px;
	}

	h2.answers_title {
		margin: 50px 0 30px 0;
	}

	.statistic {
		margin-top: 50px;
	}

	footer .row {
		margin-right: 0;
		margin-left: 0;
	}

	@media (min-width: 1200px) and (max-width: 1273px) {
		.tasks_text_img img {
			width: 100%;
		}
	}

	@media (min-width: 992px) and (max-width: 1199px) {
		.tasks_text_img img {
			width: 100%;
		}
		.what_do_haves_link a {
			padding: 0;
		}
		.number_small {
			font-style: normal;
			font-weight: 900;
			font-size: 64px;
			line-height: 98px;
			color: #1976D2;
		}
		.footer_telegram_img {
			margin-left: 30px;
		}
		.footer_telegram_text, .footer_copy {
			font-size: 14px;
		}
		.marketing_button {
			position: absolute;
			right: 40px;
			top: 50%;
		}
		.marketing_button a {
			font-size: 16px;
		}
		.marketing .container {
			background: url(https://fswho.fra1.cdn.digitaloceanspaces.com/workhard_chat/ico_telegrams.png) right top 86px no-repeat;
			background-size: 453px;
		}
	}

	@media (min-width: 769px) and (max-width: 991px) {
		.header_right .signup {
			display: none;
		}
		.header_right {
			margin: 0;
		}
		.header_left {
			margin: 30px 0 30px 30px;
		}
		.marketing_button {
			position: relative;
			right: 0;
			top: 0;
			padding: 75px 0 100px;
			text-align: center;
		}
		.top_header {
			background: url(https://fswho.fra1.cdn.digitaloceanspaces.com/workhard_chat/header_chat.png) center 0 no-repeat;
			text-align: center;
		}
		.top_header {
			height: 600px;
		}
		.marketing .container {
			background: url(https://fswho.fra1.cdn.digitaloceanspaces.com/workhard_chat/ico_telegrams.png) center bottom no-repeat;
			background-size: 480px;
		}
		.what_do_haves_link a {
			color: #0D3C61;
			text-decoration: none;
			padding: 0;
		}
		.what_do_haves_bottom_right a {
			padding: 27px 70px 28px;
		}
		.what_do_haves_bottom_right {
			margin-top: 10px;
			margin-right: 10px;
		}
		.what_do_haves_bottom_left {
			padding: 0px 15px;
			margin-top: -6px;
		}
		.how_to_work_list_item.how_to_work_4 {
			margin-top: 0;
		}
		.how_to_work_list_item {
			width: 100%;
		}
		.how_to_work_border_1,
		.how_to_work_border_2,
		.how_to_work_border_3,
		.how_to_work_border_4 {
			display: none;
		}
		.how_to_work_list {
			padding: 0;
		}
		.how_to_work_text {
			margin-bottom: 65px;
		}
		.footer_copy {
			margin-top: 15px;
			margin-right: 15px;
			text-align: center;
		}
		.footer_telegram {
			text-align: center;
		}
		.footer_telegram_img img {
			float: none;
			margin-right: 0;
			margin-top: 15px;
		}
		.footer_telegram_text {
			margin-top: 15px;
			float: none;
		}
	}

	@media (max-width: 768px) {
		.pre_footer_menu {
			flex-direction: column;
			padding: 30px;

			a {
				margin: 0;
			}
		}
		.inner_desktop,
		.how_to_work_border_1,
		.how_to_work_border_2,
		.how_to_work_border_3,
		.how_to_work_border_4 {
			display: none;
		}

		.inner_mobile {
			display: block;
		}

		h1 {
			font-style: normal;
			font-weight: bold;
			font-size: 34px;
			line-height: 125%;
			color: #242E3F;
		}

		h2 {
			font-size: 24px;
			line-height: 33px;
		}

		h3 {
			font-size: 24px;
			line-height: 33px;
		}

		.text_button {
			font-style: normal;
			font-weight: bold;
			font-size: 14px;
			line-height: 19px;
			color: #242E3F;
		}

		.text_big_size {
			font-style: normal;
			font-weight: normal;
			font-size: 16px;
		}

		.text_small_size {
			font-style: normal;
			font-weight: normal;
			font-size: 18px;
			line-height: 25px;
			color: #242E3F;
		}

		.signup {
			display: none;
		}

		.header {
			width: 100%;
		}

		.header_left {
			float: left;
			margin: 10px 0 10px 10px;
		}

		.login {
			margin-right: 30px;
		}

		.header_right {
			margin: 0;
		}

		.header_logo img {
			width: 80%;
		}

		.intro_title {
			margin-top: 33px;
		}

		.top_header {
			background: url("https://fswho.fra1.cdn.digitaloceanspaces.com/workhard_chat/header_tel.png") right -2% top 106px no-repeat, url("https://fswho.fra1.cdn.digitaloceanspaces.com/workhard_chat/header_chat.png") center 0 no-repeat;
			background-size: 50%, 100%;
		}

		.intro_text {
			margin-bottom: 60px;
			width: 50%;
		}

		.intro_button a {
			padding: 13px 45px;
		}

		.top_header {
			height: 100%;
			margin-bottom: 50px;
		}

		.bottom_header_title {
			width: 100%;
		}

		.marketing {
			margin: 80px 0;
			padding: 50px 0;
			position: relative;
			background-size: 100%;
			text-align: center;
		}
		.marketing .container {
			background: url(https://fswho.fra1.cdn.digitaloceanspaces.com/workhard_chat/ico_telegrams.png) center bottom no-repeat;
			background-size: 400px;
		}

		.marketing_text {
			font-size: 18px;
			margin-bottom: 70px;
		}

		.marketing_button {
			position: inherit;
			right: 0;
			top: 0;
			padding: 0 0 70px 0;
		}

		.tasks_text_img_1, .tasks_text_img_2, .tasks_text_img_3 {
			background: none;
		}

		.tasks_text_img img {
			width: 100%;
		}

		.tasks_text_2 {
			margin-left: 0;
		}

		.how_to_work_list_item {
			width: 100%;
		}

		.how_to_work_text {
			width: 100%;
			margin-bottom: 35px;
		}

		.how_to_work_list_item {
			margin-bottom: 30px;
		}

		.answer_link {
			background: #FFFFFF url("https://fswho.fra1.cdn.digitaloceanspaces.com/workhard_chat/arrow.png") right 25px top 40px no-repeat;
			font-size: 20px;
			padding-right: 46px;
		}

		.answer_link.active {
			background: #FFFFFF url("https://fswho.fra1.cdn.digitaloceanspaces.com/workhard_chat/arrow_active.png") right 25px top 40px no-repeat;
		}

		.how_to_work_list_item.how_to_work_4 {
			margin-top: 0;
		}

		footer {
			text-align: center;
			margin-top: -1px;
			padding-top: 15px;
		}

		.footer_telegram_img img {
			float: none;
			margin-right: 0;
			margin-top: 20px;
		}

		.what_do_haves_bottom {
			display: flex;
			flex-direction: column;
			justify-content: right;
			padding: 0;
			margin: 60px 0;
			text-align: center;
		}

		.what_do_haves_bottom_left {
			margin-bottom: 45px;
		}

		.what_do_haves_bottom_right {
			margin: 0 auto;
		}

		.what_do_haves_bottom_right a {
			padding: 28px 90px 28px;
		}
		.statistic_data_number {
			font-size: 32px;
		}
		.login a {
			font-size: 20px;
		}
		.marketing_button a {
			padding: 27px 50px 28px;
			font-size: 16px;
		}
		.answer_link.active .answer_text {
			margin-top: 30px;
		}
		.statistic_button a {
			font-style: normal;
			font-weight: bold;
			font-size: 18px;
			line-height: 25px;
			color: #FFFFFF;
			text-decoration: none;
			padding: 27px 80px 28px;
			background: #4CAF50;
			border-radius: 5px;
		}
	}

	.plans {
		padding: 50px 0;

		.inline {
			display: inline;
		}

		.description {
			margin-top: 50px;
			background: #FFFFFF;
			box-shadow: 0px 14px 40px rgba(13, 60, 97, 0.24);
			padding: 50px;
			display: flex;
			flex-wrap: wrap;
			width: 100%;

			.main {
				width: 60%;
			}


			.mobile {
				display: none;
			}


			.month {
				width: 40%;
			}

			.range-slider {
				padding: 50px 50px 25px 0px;

				.irs--round .irs-bar {
					background-color: rgba(13, 60, 97, 0.3);
				}

				.irs--round .irs-handle {
					border: 7px solid #4CAF50;
				}
			}

			.cloud {
				width: 100%;
				height: 300px;
				background-image: url(https://fswho.fra1.cdn.digitaloceanspaces.com/workhard_chat/plan-background.png);
				background-size: contain;
				background-repeat: no-repeat;
				background-position: center;
				text-align: center;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;

				.monthly {
					font-weight: 900;
					font-size: 2rem;
					line-height: 46px;
					color: #0D3C61;
					margin-left: 10%;

					.price {
						font-weight: 900;
						font-size: 3.3rem;
						line-height: 74px;
						color: #1976D2;
					}
				}

				.desc {
					display: flex;
					flex-direction: column;
					margin-left: 10%;

					span {
						font-weight: normal;
						font-size: 1.1rem;
					}
				}
			}

			h3 {
				line-height: 1;
				font-size: 18px;
				font-weight: bold;
			}

			ul {
				padding: 15px;

				li {
					span {
						color: black;
					}
				}
			}
		}
	}

	@media (max-width: 991px) {
		.plans {

			.description {
				padding: 20px;

				.main {
					width: 100%;
				}

				.range-slider {
					width: 100%;
					padding: 50px 0;
				}

				.cloud {
					background-image: url(https://fswho.fra1.cdn.digitaloceanspaces.com/workhard_chat/plan-background-mobile.png);

					.monthly, .desc {
						margin-left: 0;
					}
				}

				.month {
					width: 100%;

				}


				.desktop {
					display: none;
				}

				.mobile {
					display: block;
				}
			}

		}
	}
}
